<template>
  <basic-modal @open="openModal" width="700" transition="slide-x-transition">
    <template #header>
      <divider
        title="special model"
        sub-title="make a model special"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="mt-4 px-6 pr-13 modal__body">
        <v-row>
          <v-col
            class="social__model__switch"
            v-for="specialView in getSpecialViews"
            :key="specialView.id"
            :class="specialView.style.class"
          >
            <v-switch
              inset
              :color="specialView.style.color"
              class="social__switch"
              @change="changeSwitch(specialView.id)"
              v-model="switches[specialView.id]"
            >
              <template v-slot:label>{{ specialView.description }}</template>
            </v-switch>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-5 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel btn__size__18"
          text="cancel"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign site__button width-auto ml-5 btn__size__16"
          text="save the data"
          :loading="requestLoading"
          @click.native="save"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {cloneDeep} from "lodash"
export default {
  name: "modal_specialModelModal",
  data() {
    return {
      form: {},
      switches: [],
      requestLoading : false,
    };
  },
  computed: {
    ...mapGetters("specialView", ["getSpecialViews"]),
  },
  methods: {
    ...mapActions("specialView", ["loadSpecialViews"]),
    ...mapActions("model", ["updateSpecialView"]),
    async save() {
      try {
        this.requestLoading = true;
        this.updateSpecialView(this.form);
        this.$_closeModal();
      } catch (e) {
        return e;
      } finally {
        this.requestLoading = false;
      }
    },
    openModal({ model }) {
      this.form = cloneDeep(model);
      this.setOnSwitches();
    },
    setOnSwitches() {
      this.form.special_views.map((switchId) => (this.switches[switchId] = true));
    },
    changeSwitch(id) {
      const value = this.switches[id];
      if (value) {
        this.form.special_views.push(id);
      } else {
        this.$delete(
          this.form.special_views,
          this.form.special_views.findIndex((x) => x === id)
        );
      }
    },
  },
  created() {
    this.loadSpecialViews();
  },
};
</script>
<style scoped>
.social__model__switch {
  border-bottom: 1px solid var(--color-light-gray) !important;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
.modal__body {
  padding-bottom: 200px;
}
</style>
