<template >
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div class="mb-2 mt-4">
        <ModelSelectedAllItems v-if="getAllModelSelected" />
        <ModelSelectedItems v-if="!getAllModelSelected && getModelSelected.length"/>
        <ActiveFilters :filters="getFiltersModel" :sort="getSorts"/>
      </div>
      <div>
        <v-row class="justify-content-center pb-4 wa__models">
          <v-col cols="12" md="2">
            <ModelFilter />
          </v-col>
          <v-col cols="12" md="10">
            <ModelItems />
          </v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
    <div class="text-center">
      <ModelJapaneseTranlationModal />
    </div>
  </div>
</template>
<script>
import ModelSelectedItems from "../components/Model/SelectedItems.vue";
import ModelSelectedAllItems from "../components/Model/SelectedAllItmes.vue";
import ModelItems from "../components/Model/Items";
import ActiveFilters from "../components/Model/ActiveFilters.vue";
import ModelFilter from "../components/Model/Filter";
import ModelJapaneseTranlationModal from "../components/Model/JapaneseTranlationModal.vue";
import { mapGetters,mapMutations } from "vuex";
export default {
  components: {
    ModelFilter,
    ModelItems,
    ModelJapaneseTranlationModal,
    ModelSelectedItems,
    ModelSelectedAllItems,
    ActiveFilters
  },
  computed: {
    ...mapGetters("model", ["getModelSelected", "getAllModelSelected","getFiltersModel","getSorts"]),
  },
  methods: {
    ...mapMutations('model', ['DESTROY_HISTORY']),
  },
  destroyed(){
    // this.DESTROY_HISTORY()
  }
};
</script>