<template>
  <list :title="$_t('models')" class="wa__large">
    <hr class="mt-0" />
    <template>
      <v-row class="px-7 pb-1 d-none d-md-flex">
        <v-col cols="3" class="wa__model__title--title pl-12">
          <div class="d-flex mb-1">
            <Checkbox
              class="d-inline-flex ml-1"
              @change="toggleSelectAll"
              name="checkbox"
              id="checkbox"
              v-model="getSelectAllCheckbox"
            />
            <span class="ml-3 mt-1">{{ $_t("cellphoneOrName") }}</span>
          </div>
        </v-col>
        <v-col cols="1" class="wa__model__title--title pl-3"
          ><span>{{ $_t("status") }}</span></v-col
        >
        <v-col cols="3" class="wa__model__title--title text-center"
          ><span>{{ $_t("jobs") }}</span></v-col
        >
        <v-col cols="2" class="wa__model__title--title"
          ><span class="pl-1">user info</span></v-col
        >
        <v-col cols="3" class="wa__model__title--title text-right pr-16"
          ><span>{{ $_t("controls") }}</span></v-col
        >
      </v-row>

      <data-iterator-load-more
        :items="getModels"
        :is-filtered="isFilteredModel"
        @pagination="changePagination"
        :pagination="getPaginationModel"
        :loading="getModelLoading"
        class="row mt-0 px-4"
      >
        <template v-slot:loading>
          <loading />
        </template>
        <template #no-items>
          <!-- fix component -->
          <!-- <no-items :src="$_staticPath('images/global/animated/product.gif')" route-name="AddProduct" textFa=" هنوز هیچ محصولی ثبت نکرده اید! " textEn=" No Products Yet! " themeColor="product" btnText="ثبت اولین  محصول" btnIcon="plus" /> -->
          <no-items textEn=" No Products Yet! " />
        </template>
        <template #no-results>
          <!-- fix component -->
          <!-- <no-items :src="$_staticPath('images/global/animated/no-result.gif')" textFa=" متاسفانه محصولی مطابق با فیلتر های شما پیدا نکردیم! " textEn=" Sorry, No Product! " themeColor="product" btnText=" حذف فیلتر ها " /> -->
          <no-items />
        </template>

        <template v-slot:items="{ item }">
          <ModelItem :key="item.id" :model="item" />
        </template>
      </data-iterator-load-more>
      <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
        ---------------------------------------------------------------------->
      <div class="text-center">
        <project-assign v-if="isModal('modal_assignProject')" />
        <specialModelModal v-if="isModal('modal_specialModelModal')" />
        <sendEmailModal :templates="getUserTemplates" v-if="isModal('modal_sendEmail')" />
        <specialAttributeModal v-if="isModal('modal_special_attribute')" />
        <emailConfirmationModal
          :filters="getFiltersModel"
          v-if="isModal('modal_emailConfirmation')"
        />
      </div>
      <!-- --------------------------------------------------------------------
                                    Modals :: END
        ---------------------------------------------------------------------->
    </template>
  </list>
</template>

<script>
import ModelItem from "./Item";
import ProjectAssign from "../Project/Modal/ModalAssign.vue";
import specialModelModal from "./SpecialModelModal";
import sendEmailModal from "./SendEmail.vue";
import specialAttributeModal from "./Modal/SpecialAttribute.vue";
import emailConfirmationModal from "@/components/Global/Modal/EmailConfirmation.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import DataIteratorLoadMore from "../Global/Input/DataIteratorLoadMore";
export default {
  components: {
    DataIteratorLoadMore,
    ModelItem,
    ProjectAssign,
    specialModelModal,
    specialAttributeModal,
    sendEmailModal,
    emailConfirmationModal,
  },
  computed: {
    ...mapGetters("model", [
      "getModels",
      "getPaginationModel",
      "getFiltersModel",
      "isFilteredModel",
      "getModelLoading",
      "getSelectAllCheckbox"
    ]),
    ...mapGetters("template", [
      "getUserTemplates"
    ]),
    ...mapGetters("modal", ["isModal"]),
  },
  methods: {
    ...mapActions("model", ["loadModels", "toggleSelectAll"]),
    ...mapActions("template", ["loadTemplates"]),
    ...mapMutations("model", ["SET_PAGINATION","FROM_ZERO","SET_MODELS"]),
    changePagination(page) {
      if (this.getPaginationModel.page !== page) {
        this.SET_PAGINATION({
          pagination: page,
        });
        // this.FROM_ZERO(true)
        this.loadModels();
      }
    },
  },
  destroyed() {
    this.SET_MODELS([])
  },
  created() {
    this.loadModels();
    this.loadTemplates();
  },
};
</script>

<style scoped>
.wa__model__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 16px;
}

.wa__model__title--title {
  font-family: "Montserrat-light";
  font-size: 12px;
  color: var(--color-gray);
  text-transform: uppercase;
  letter-spacing: 1px;
}
</style>
