<template>
  <!--model item-->
  <div :class="['wa__model mb-2',{'model-updated':model.model_updated_not_appended.items_updated!=false}]">
    <v-row class="text-left">
      <v-col cols="4" lg="3" class="wa__model__name px-0">
        <!--model image-->
        <router-link
          :to="{
            name: 'modelDetail',
            params: { id: model.id, locale: $_getlocaleParam() },
          }"
        >
          <div class="wa__model__name--image">
            <figure>
              <img
                v-if="model.model.thumbnail"
                :src="model.model.thumbnail"
                alt="image"
              />
              <img v-else src="https://via.placeholder.com/55x65" alt="image" />
            </figure>
          </div>
        </router-link>
        <!--model name-->
        <div class="d-flex align-items-center h-100">
          <div>
            <Checkbox
              v-model="getModelSelected"
              class="ml-3 mr-2"
              name="checkbox"
              :item="model"
              @change="changeModelSelected"
            />
          </div>
          <div class="d-flex align-center h-100 mb-1">
            <router-link
              :to="{
                name: 'modelDetail',
                params: { id: model.id, locale: $_getlocaleParam() },
              }"
            >
              <!-- <Name
                :title="model.name"
                :sub-title="model.cell_phone"
                :variable="model.cell_phone ? false : true"
              /> -->

              <div class="d-flex align-center h-100">
                <div>
                  <div class="d-flex align-center h-100 mb-1">
                    <v-chip
                      label
                      x-small
                      color="#e6e6e679"
                      class="model__chip px-2 mr-2"
                      text-color="black"
                      >NO. {{ model.unique_id }}
                    </v-chip>
                    <v-chip
                      v-if="model.model_updated_not_appended.items_updated!=false"
                      label
                      x-small
                      color="blue"
                      class="model__chip px-2 mr-2"
                      text-color="white"
                      >Updated: {{ model.model_updated_not_appended.items_updated }}
                    </v-chip>
                    <v-chip
                      v-if="model.special_views.includes(6)"
                      label
                      x-small
                      color="#EE3552"
                      class="model__chip"
                      text-color="white"
                    >
                      <v-icon class="WMi-info-2" color="white" x-small></v-icon>
                      HIDDEN
                    </v-chip>
                  </div>
                  <div class="d-flex align-center h-100">
                    <div style="line-height: 12px">
                      <div class="model__name">
                        {{ model.name }}
                        <v-chip
                          v-if="model.special_views.includes(4)"
                          label
                          x-small
                          color="black"
                          class="model__chip px-1"
                          text-color="white"
                        >
                          pro
                        </v-chip>
                      </div>
                      <small class="model__country">{{
                        model.country.name
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </v-col>
      <!--status secttion-->
      <v-col cols="2" class="wa__model__status d-none d-lg-block pl-5 pt-2">
        <!--model status-->
        <template v-if="model.modelProjects.length">
          <div class="d-flex align-center h-100">
            <div class="d-grid">
              <div class="wa__model__status--hired pl-3">HIRED BY:</div>
              <div class="wa__model__status--text">
                <div class="wa__model__status--title pl-3">
                  {{ model.modelProjects[model.modelProjects.length - 1].name }}
                </div>

                <div class="wa__model__status--operations pl-3">
                  <block-button
                      height="12"
                      class="btn-operations"
                      :text="model.model_updated_not_appended['items_updated']"
                      color="red"
                      bg-color="white"
                  ></block-button>
                  <block-button
                    height="12"
                    class="btn-operations"
                    :text="model.busyStatus.text"
                    :color="model.busyStatus.color"
                    :bg-color="model.busyStatus.bgColor"
                  ></block-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <block-button
            :text="model.busyStatus.text"
            class="btn-available"
            :color="model.busyStatus.color"
            :bg-color="model.busyStatus.bgColor"
            height="24"
          ></block-button>
        </template>
      </v-col>
      <!-- /status section-->
      <!-- model jobs-->
      <v-col cols="2" class="wa__model__jobs d-none d-lg-block pl-5 pt-2">
        <div class="d-flex align-center justify-start h-100">
          <div class="job__box">
            <block-button
              class="btn-join"
              :text="'joined from ' + model.created_at"
              height="13"
            />
            <router-link
              :to="{
                name: 'projectsModel',
                params: { locale: this.$_getlocaleParam(), model: model.id },
              }"
              class="text-decoration-none d-block"
            >
              <block-button
                class="btn-jobs w-100"
                height="auto"
                :text="model.projectCount + ' Total Jobs'"
                color="black"
                bg-color="bgGray"
                @click.native="$_openModal('projectList')"
              />
            </router-link>
          </div>
        </div>
      </v-col>
      <!-- /date-status section-->
      <v-col lg="2" cols="4" class="pt-2">
        <div class="d-flex align-center h-100">
          <div class="model__email__box">
            <div class="model__email">{{ model.email }}</div>
            <div>
              <v-chip
                v-if="model.birthday"
                label
                x-small
                color="#e6e6e679"
                class="model__chip px-2 mr-1"
                text-color="black"
                >{{ age }} Year old
              </v-chip>
              <v-chip
                v-if="model.cell_phone"
                label
                x-small
                color="#e6e6e679"
                class="model__chip px-2 mr-1"
                text-color="black"
                >{{ model.cell_phone }}
              </v-chip>
              <v-chip
                v-if="getValidUntilDays"
                label
                x-small
                color="#ee355115"
                class="model__chip px-2"
                text-color="#EE3552"
                >{{ getValidUntilDays }} days of visa
              </v-chip>
            </div>
          </div>
        </div>
      </v-col>
      <!-- control section-->
      <v-col
        cols="4"
        lg="3"
        class="wa__model__control d-flex justify-content-end align-items-center"
      >
        <!-- model controls-->
<!--        <circle-button-->
<!--            v-if="model.model_updated_not_appended!==null"-->
<!--            icon="WMi-upload"-->
<!--            color="red"-->
<!--            bg-color="bgRed"-->
<!--            @click.native="appendUpdate()"-->
<!--        />-->
        <circle-button
            v-if="!model.model_verified_at"
            icon="WMi-ok"
            color="cyan"
            bg-color="bgCyan"
            @click.native="verify()"
        />
        <circle-button
          text="special model"
          icon="WMi-star"
          color="cyan"
          bg-color="bgCyan"
          @click.native="$_openModal('specialModelModal', { model: model })"
        ></circle-button>
        <circle-button
          text="email"
          icon="WMi-mail-alt"
          color="black"
          bg-color="bgGray"
          @click.native="$_openModal('sendEmail', { model: model })"
        ></circle-button>
        <!-- <circle-button
          :icon="model.model_verified_at ? 'mdi-eye' : 'mdi-eye-off'"
          color="black"
          bg-color="bgBlack"
          @click.native="changeVisibility"
        ></circle-button> -->

        <router-link
          :to="{
            name: 'modelGalleries',
            params: { id: model.id, locale: $_getlocaleParam() },
          }"
        >
          <circle-button
            text="galleries"
            icon="WMi-picture"
            color="black"
          ></circle-button>
        </router-link>
        <router-link
          :to="{
            name: 'modelEditByAdmin',
            params: { id: model.id, locale: $_getlocaleParam() },
          }"
        >
          <circle-button icon="WMi-pencil" color="black"></circle-button>
        </router-link>
        <div>
          <a :href="localeUrl(`${$baseUrl}/model/${model.unique_id}`)">
            <circle-button
              icon="WMi-link"
              color="black"
              bgColor="transparent"
            ></circle-button>
          </a>
        </div>
        <menu-item :items="menuItems" />
      </v-col>
      <!-- /control section-->
    </v-row>
  </div>
</template>
<script>
import toast from "@/utils/toast";
// import Name from "../Global/Section/Name.vue";
import { mapActions, mapGetters } from "vuex";
import UserRepository from "../../abstraction/repository/userRepository";
const userRepository = new UserRepository();
export default {
  components: {
    // Name,
  },
  props: {
    model: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapActions("model", [
      "deleteModel",
      "removeFromSelected",
      "addToSelected",
      "updateModelVisibility",
    ]),
    async verify() {
      try {
        toast.question(
          this.$_trans("toast.verify_model_msg"), this.$_trans("toast.verify_model_title"),
          async () => {
            const response = await userRepository.verify(this.model.id);
            if (response) {
              this.model.model_verified_at = true;
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async appendUpdate() {
      try {
        toast.question(
          this.$_trans("toast.append_update_msg"), this.$_trans("toast.append_update_title"),
          async () => {
            const response = await userRepository.appendUpdate(this.model.id);
            if (response) {
              this.model.model_updated_not_appended = response.model_updated_not_appended;
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async changeVisibility() {
      await this.updateModelVisibility(this.model);
    },
    deleteMessage() {
      toast.question( this.$_trans("toast.del_model_item_msg"), this.$_trans("toast.del_model_item_title"), () => {
        this.deleteModel(this.model.id);
      });
    },
    changeModelSelected() {
      const index = this.getModelSelected.findIndex(
        (x) => x.id === this.model.id
      );
      if (index >= 0) {
        this.addToSelected(this.model);
      } else {
        this.removeFromSelected(this.model);
      }
    },
  },
  computed: {
    ...mapGetters("model", ["getModelSelected"]),
    getValidUntilDays() {
      if (this.model.model.valid_until) {
        const now = new Date().getTime();
        const expire = new Date(this.model.model.valid_until).getTime();
        const diffrent = Math.floor((expire - now) / (1000 * 60 * 60 * 24));
        return diffrent > 0 ? diffrent : 0;
      } else {
        return null;
      }
    },
    age() {
      let ageDifMs = Date.now() - new Date(this.model.birthday).getTime();
      var ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    menuItems() {
      return [
        {
          text: "Translate",
          icon: "WMi-globe",
          click: (self) => {
            self.$_openModal("japaneseTranlationModal", {
              model: self.model,
            });
          },
        },
        {
          text: "assign project",
          icon: "WMi-plus",
          click: (self) => {
            self.$_openModal("assignProject", {
              model: {
                model_id: self.model.id,
              },
            });
          },
        },
        {
          text: "special attributes",
          icon: "WMi-user-linear",
          click: (self) => {
            self.$_openModal("special_attribute", { model: { ...this.model } });
          },
        },
        {
          text: "delete",
          icon: "WMi-trash",
          click: (self) => {
            self.deleteMessage();
          },
        },
      ];
    },
  },
};
</script>
<style scoped>
/* parent */
.col-3:first-child {
  padding-left: 1px;
  padding-right: 1px;
}
.d-grid {
  display: grid;
}
.wa__model {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  width: 100%;
  height: 67px;
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px;
}
/* model item */
.wa__model__name {
  display: flex;
  align-items: flex-end;
  margin-top: -5px;
  cursor: pointer;
}
.model-updated{
  border:2px dashed red;
}
.model__name {
  font-family: "montserrat-regular";
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 0.9rem;
}
.model__country {
  font-family: "montserrat-light";
  color: #4a4a4a;
  /* position: relative;
  top: -0.1rem; */
}
.model__email__box {
  line-height: 17px;
  display: grid;
}
.model__email {
  font-size: 15px;
  font-family: "montserrat-regular";
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.wa__model__name--image {
  height: 66px;
}

.wa__model__name--image figure img {
  height: 65px;
  width: auto;
  border-radius: 4px;
}

.wa__model__name a {
  text-decoration: none;
  display: block;
  color: initial;
}

.wa__model__status--hired {
  color: var(--color-light-gray);
  font-size: 10px;
}
.wa__model__status--text {
  line-height: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.wa__model__status--title {
  font-size: 12px;
  text-transform: uppercase;
}

.btn-operations {
  font-size: 8px;
  cursor: default !important;
  padding: 4px 5px !important;
  border-radius: 2px !important;
  letter-spacing: 1px !important;
}
.wa__model__control {
  margin-top: -8px !important;
}
/* button */

.wa__model__control a {
  text-decoration: none;
  display: block;
  margin-right: 7px;
}
.job__box {
  line-height: 25px;
}
.btn-join {
  font-family: "Montserrat-regular";
  color: var(--color-gray);
  font-size: 11px;
  letter-spacing: 0px !important;
  border-radius: 1px !important;
  background-color: var(--color-bg-gray) !important;
  cursor: default !important;
}

.btn-jobs {
  font-family: "Montserrat-bold";
  font-size: 13px;
  font-weight: 900;
  padding: 1px 9px !important;
  position: relative;
  top: -0.5rem;
  border-radius: 1px !important;
  letter-spacing: 2px;
}
/*If it were not for btn-joined*/
.btn-jobs--only {
  top: 1.2rem !important;
}
.btn-available {
  font-family: "Montserrat-regular";
  padding: 10px !important;
  margin-top: 15px;
  font-size: 16px;
}
</style>
<style>
.model__chip .v-chip__content {
  font-family: "montserrat-regular";
  font-size: 8px !important;
}
</style>
