<template>
  <basic-modal @open="openModal" width="1200" transition="slide-x-transition">
    <template #header>
      <divider
        title="japanese translation"
        sub-title="LET'S MAKE IT LOCAL"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="mt-4 px-15 pr-13">
        <ul class="d-flex pl-0 list-unstyled">
          <li class="pr-2">
            <v-text-field
              label="last name"
              color="SonicSilver"
              class="mt-0 pt-0"
              v-model="form.last_name_jpn"
              dense
              outlined
            ></v-text-field>
          </li>
          <li class="px-2">
            <v-text-field
              label="first name"
              color="SonicSilver"
              class="mt-0 pt-0"
              v-model="form.first_name_jpn"
              dense
              outlined
            ></v-text-field>
          </li>
        </ul>
        <div class="mb-6">
          <v-row class="mt-0">
            <v-col cols="12" class="text-justify pt-0">
              <v-textarea
                color="SonicSilver"
                rows="3"
                label="job experience"
                v-model="form.model.job_experience_jpn"
                dense
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="text-justify pt-0">
              <v-textarea
                rows="3"
                label="bio"
                v-model="form.bio_jpn"
                color="SonicSilver"
                dense
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="text-justify pt-0">
              <v-textarea
                rows="3"
                label="skills"
                v-model="form.model.skills_jpn"
                color="SonicSilver"
                dense
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="text-justify pt-0">
              <v-textarea
                rows="3"
                label="tattoo"
                v-model="form.model.tattoo_jpn"
                dense
                color="SonicSilver"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-10 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel pL-0 btn__size__18"
          text="cancel"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign site__button width-auto ml-5 btn__size__16"
          text="translate"
          icon="WMi-plus-linear"
          :loading="requestLoading"
          @click.native="updateModelByAdmin"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "modal_japaneseTranlationModal",
  data() {
    return {
      form: {},
      requestLoading: false,
    };
  },
  methods: {
    openModal({ model }) {
      this.form = { ...model };
    },
    ...mapActions("model", ["updateByAdmin"]),
    async updateModelByAdmin() {
      try {
        this.requestLoading = true;
        await this.updateByAdmin(this.form);
        this.$_closeModal();
      } catch (e) {
        return e;
      } finally {
        this.requestLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}
.description {
  font-size: 14px;
  font-family: "Montserrat-bold" !important;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
</style>
