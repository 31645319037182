let defaultFilter = {
  created_at: {
    type: "between",
    val1: null,
    val2: null,
    name: "created at",
  },
  first_name: {
    type: "like",
    val: null,
    name: "model first name",
  },
  last_name: {
    type: "like",
    val: null,
    name: "model last name",
  },
  last_name_jpn: {
    type: "like",
    val: null,
    name: "model last name japanese",
  },
  first_name_jpn: {
    type: "like",
    val: null,
    name: "model first name japanese",
  },
  unique_id: {
    type: "like",
    val: null,
    name: "model id",
  },
  email: {
    type: "like",
    val: null,
    name: "email",
  },
  cell_phone: {
    type: "like",
    val: null,
    name: "cell phone",
  },
  instagram_id: {
    type: "like",
    val: null,
    name: "instagram id",
  },
  model_verified_at: {
    type: "in",
    val: null,
    name: "model verified at",
  },
  sort_by: {
    type: "in",
    val: null,
    name: "sort by",
  },
  language: {
    type: "in",
    val: null,
    name: "language",
    values: [],
    multiple: true,
  },
  language_level: {
    type: "in",
    val: null,
    name: "language level",
    values: [],
    multiple: true,
  },
  hair_color: {
    type: "in",
    val: null,
    name: "hair color",
  },
  eye_color: {
    type: "in",
    val: null,
    name: "eye color",
    values: [],
    multiple: true,
  },
  gender_id: {
    type: "in",
    val: null,
    name: "gender",
    values: [],
    multiple: true,
  },
  ethnic_id: {
    type: "in",
    val: null,
    name: "nationality",
    values: [],
    multiple: true,
  },
  country_id: {
    type: "in",
    val: null,
    name: "country",
    values: [],
    multiple: true,
  },
  city: {
    type: "like",
    val: null,
    name: "city",
  },
  model_type: {
    type: "in",
    val: [],
    name: "model type",
    values: [],
    multiple: true,
  },
  birthday: {
    type: "between",
    val1: null,
    val2: null,
    name: "birthday",
  },
  height: {
    type: "between",
    val1: null,
    val2: null,
    name: "height",
  },
  waist: {
    type: "between",
    val1: null,
    val2: null,
    name: "waist",
  },
  bust: {
    type: "between",
    val1: null,
    val2: null,
    name: "bust",
  },
  hips: {
    type: "between",
    val1: null,
    val2: null,
    name: "hips",
  },
  followers: {
    type: "between",
    val1: null,
    val2: null,
    name: "followers count",
  },
  shoe_size: {
    type: "between",
    val1: null,
    val2: null,
    name: "shoe size",
  },
  valid_until: {
    type: "between",
    val1: null,
    val2: null,
    name: "valid unit",
  },
  special_views : {
    type: "in",
    val: [],
    name: "placeholder",
    values: [],
    multiple: true,
  },
  attribute_values : {
    type: "in",
    val: [],
    toString : [],
  },
  model_verification : {
    type: "in",
    val: "",
    name : "model verification",
    multiple: false,
    values: [],
  },
};
// TODO: fix show in modal email config & box active filters
// TODO: fix checkbox all models + client + ....

export default defaultFilter;
