<template>
  <v-form @submit.prevent="submit" class="h-100 mb-3">
    <list :title="$_t('filters')" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12">
          <v-text-field
              :label="$_t('modelFirstName')"
              class="no-error-msg"
              color="SonicSilver"
              outlined
              dense
              v-model="filter.first_name.val"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('modelLastName')"
              class="no-error-msg"
              color="SonicSilver"
              outlined
              dense
              v-model="filter.last_name.val"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('modelLastNameJapanese')"
              class="no-error-msg"
              color="SonicSilver"
              outlined
              dense
              v-model="filter.last_name_jpn.val"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('modelFirstNameJapanese')"
              class="no-error-msg"
              color="SonicSilver"
              outlined
              dense
              v-model="filter.first_name_jpn.val"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('modelId')"
              class="no-error-msg"
              color="SonicSilver"
              outlined
              dense
              v-model="filter.unique_id.val"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('email')"
              class="no-error-msg"
              color="SonicSilver"
              outlined
              dense
              v-model="filter.email.val"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('phone')"
              class="no-error-msg"
              color="SonicSilver"
              outlined
              dense
              v-model="filter.cell_phone.val"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
              class="no-error-msg"
              :items="modelVerifications"
              item-text="name"
              item-value="id"
              outlined
              dense
              :label="$_t('modelVerifications')"
              color="SonicSilver"
              v-model="filter.model_verification.val"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
              class="no-error-msg"
              :items="getSpecialViews"
              item-text="name"
              item-value="id"
              outlined
              dense
              multiple
              :label="$_t('placeHolder')"
              color="SonicSilver"
              v-model="filter.special_views.val"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" v-for="attr in getAttributes" :key="attr.id">
          <v-autocomplete
              class="no-error-msg"
              :items="attr.attributeValues"
              item-text="name"
              item-value="id"
              outlined
              dense
              multiple
              :label="attr.name"
              color="SonicSilver"
              v-model="filter.attribute_values.val"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
              class="no-error-msg"
              v-model="model_verified_at"
              :items="verifies"
              item-text="name"
              outlined
              dense
              :label="$_t('verify')"
              color="SonicSilver"
              return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
              class="no-error-msg"
              v-model="sorts"
              :items="sortBy"
              :label="$_t('sortBy')"
              item-text="name"
              item-value="id"
              outlined
              dense
              color="SonicSilver"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <div>
            <date-input
                :label="$_t('dateAfter')"
                v-model="filter.created_at.val1"
                color="SonicSilver"
                class="no-error-msg"
                outlined
                dense
            ></date-input>
          </div>
        </v-col>
        <v-col cols="12">
          <date-input
              :label="$_t('dateBefore')"
              class="no-error-msg"
              v-model="filter.created_at.val2"
              outlined
              dense
              color="SonicSilver"
          ></date-input>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
              v-model="filter.gender_id.val"
              item-text="name"
              item-value="id"
              :items="getGenders"
              :label="$_t('gender')"
              outlined
              dense
              multiple
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <block-button
              height="30"
              class=""
              :text="$_t('selectAll')"
              color="black"
              @click.native="selectAll('getModelTypes','model_type')"
              outlined
              dense
          ></block-button>

          <block-button
              height="30"
              class="btn__size__14"
              :text="$_t('clearAll')"
              color="black"
              @click.native="clearAll('model_type')"
              outlined
              dense
          ></block-button>
          <v-autocomplete
              :items="getModelTypes"
              v-model="filter.model_type.val"
              multiple
              item-text="name"
              item-value="id"
              :label="$_t('modelType')"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <block-button
              height="30"
              class=""
              :text="$_t('selectAll')"
              color="black"
              @click.native="selectAll('getEthnics','ethnic_id')"
              outlined
              dense
          ></block-button>
          <block-button
              height="30"
              class="btn__size__14"
              :text="$_t('clearAll')"
              color="black"
              @click.native="clearAll('ethnic_id')"
              outlined
              dense
          ></block-button>
          <v-autocomplete
              v-model="filter.ethnic_id.val"
              :items="getEthnics"
              item-text="name"
              item-value="id"
              :label="$_t('nationality')"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <block-button
              height="30"
              class=""
              :text="$_t('selectAll')"
              color="black"
              @click.native="selectAll('getCountries','country_id')"
              outlined
              dense
          ></block-button>
          <block-button
              height="30"
              class="btn__size__14"
              :text="$_t('clearAll')"
              color="black"
              @click.native="clearAll('country_id')"
              outlined
              dense
          ></block-button>
          <v-autocomplete
              class="pt-0 mt-0"
              :items="getCountries"
              item-value="id"
              item-text="name"
              :label="$_t('country')"
              color="SonicSilver"
              v-model="filter.country_id.val"
              outlined
              dense
              multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="filter.city.val"
              :label="$_t('city')"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <date-input
              :label="$_t('valid_until')"
              v-model="filter.valid_until.val1"
              color="SonicSilver"
              class="no-error-msg"
              outlined
              dense
          ></date-input>
        </v-col>
        <v-col cols="12">
          <block-button
              height="30"
              class=""
              :text="$_t('selectAll')"
              color="black"
              @click.native="selectAll('getLanguages','language')"
              outlined
              dense
          ></block-button>
          <block-button
              height="30"
              class="btn__size__14"
              :text="$_t('clearAll')"
              color="black"
              @click.native="clearAll('language')"
              outlined
              dense
          ></block-button>
          <v-autocomplete
              :items="getLanguages"
              item-value="id"
              item-text="name"
              v-model="filter.language.val"
              :label="$_t('language')"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
              :items="getLanguageLevels"
              item-value="id"
              item-text="name"
              v-model="filter.language_level.val"
              outlined
              dense
              :label="$_t('languageLevel')"
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <block-button
              height="30"
              class=""
              :text="$_t('selectAll')"
              color="black"
              @click.native="selectAll('getEyeColors','eye_color')"
              outlined
              dense
          ></block-button>
          <block-button
              height="30"
              class="btn__size__14"
              :text="$_t('clearAll')"
              color="black"
              @click.native="clearAll('eye_color')"
              outlined
              dense
          ></block-button>
          <v-autocomplete
              v-model="filter.eye_color.val"
              :items="getEyeColors"
              item-value="id"
              item-text="name"
              :label="$_t('eyeColor')"
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              outlined
              dense
              multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <block-button
              height="30"
              class=""
              :text="$_t('selectAll')"
              color="black"
              @click.native="selectAll('getHairColors','hair_color')"
              outlined
              dense
          ></block-button>
          <block-button
              height="30"
              class="btn__size__14"
              :text="$_t('clearAll')"
              color="black"
              @click.native="clearAll('hair_color')"
              outlined
              dense
          ></block-button>
          <v-autocomplete
              v-model="filter.hair_color.val"
              :items="getHairColors"
              item-text="name"
              text-value="id"
              :label="$_t('hairColor')"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('instagramId')"
              class="no-error-msg"
              outlined
              dense
              color="SonicSilver"
              v-model="filter.instagram_id.val"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- height filter -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="filter.followers.val1"
              label="followers - min"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="filter.followers.val2"
              label="followers - max"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- age filter -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              label="age - min"
              v-model="ageMin"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="age - max"
              v-model="ageMax"
              outlined
              dense
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- height filter -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="filter.height.val1"
              label="height - min"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="filter.height.val2"
              label="height - max"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- waist filter -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="filter.waist.val1"
              label="waist - min"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="filter.waist.val2"
              label="waist - max"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- bust filter -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="filter.bust.val1"
              label="bust - min"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="filter.bust.val2"
              label="bust - max"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- hips filter -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="filter.hips.val1"
              label="hips - min"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="filter.hips.val2"
              label="hips - max"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- shoe size filter -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="filter.shoe_size.val1"
              label="shoe size - min"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="filter.shoe_size.val2"
              label="shoe size - max"
              class="no-error-msg pt-0 mt-0"
              color="SonicSilver"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="my-3">
        <v-col cols="4" class="pr-0">
          <block-button
              height="30"
              class="w-100 cancel__button br__mode btn__size__14"
              :text="$_t('clear')"
              color="black"
              @click.native="clearFilter"
              outlined
              dense
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
              height="30"
              class="w-100 site__button width-auto add__mode btn__size__18"
              type="submit"
              :text="$_t('execute')"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import defaultFilter from "@/components/Model/defaultFilter"
export default {
  data: () => ({
    sorts: "created_at,desc",
    filter: {},
    verifies: [
      { name: "all", id: null },
      { name: "verified", id: "(ge)2000-01-01 00:00:00" },
      { name: "not verified", id: "(null)" },
    ],
    modelVerifications: [
      {id: 'verified', name: 'Verified'},
      {id: 'un_verified', name: 'Unverified'},
      {id: 'joined_and_unverified', name: 'Joined and Unverified'},
      {id: 'updated_and_unverified', name: 'Updated and Unverified'},
    ],
    sortBy: [
      { name: "joined date", id: "created_at,desc" },
      { name: "height", id: "height,desc" },
      { name: "waist", id: "waist,desc" },
      { name: "hips", id: "hips,desc" },
    ],
  }),
  computed: {
    ...mapGetters("hairColor", ["getHairColors"]),
    ...mapGetters("eyeColor", ["getEyeColors"]),
    ...mapGetters("country", ["getCountries"]),
    ...mapGetters("ethnic", ["getEthnics"]),
    ...mapGetters("modelType", ["getModelTypes"]),
    ...mapGetters("status", ["getStatuses"]),
    ...mapGetters("gender", ["getGenders"]),
    ...mapGetters("language", ["getLanguages"]),
    ...mapGetters("languageLevel", ["getLanguageLevels"]),
    ...mapGetters("model", ["getFiltersModel"]),
    ...mapGetters("specialView", ["getSpecialViews"]),
    ...mapGetters("attribute", ["getAttributes"]),
    valid_until: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) {
          var expire = new Date();
          const today = new Date();
          this.filter.valid_until.val1 =
              today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
          expire.setDate(expire.getDate() + parseInt(value));
          expire = expire.toISOString().slice(0, 10);
        } else {
          expire = value;
        }
        this.filter.valid_until.val2 = expire;
        this.$emit("input", value);
      },
    },
    ageMin: {
      get() {
        return this.value;
      },
      set(value) {
        var date = new Date();
        date.setDate(date.getDate());
        date.setFullYear(date.getFullYear() - value);
        let finalDate =
            date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        this.filter.birthday.val2 = finalDate;
      },
    },
    model_verified_at: {
      get() {
        return this.value ? this.value : this.verifies[0];
      },
      set(value) {
        this.filter.model_verified_at.val = value.id;
        this.filter.model_verified_at.value = value.name;
      },
    },
    ageMax: {
      get() {
        return this.value;
      },
      set(value) {
        var date = new Date();
        date.setDate(date.getDate());
        date.setFullYear(date.getFullYear() - value);
        let finalDate =
            date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        this.filter.birthday.val1 = finalDate;
      },
    },
  },
  methods: {
    selectAll(model,filter_model){
      this.filter[filter_model].val = this[model].map((item)=>{
        return item.id;
      })
    },
    clearAll(filter_model){
      this.filter[filter_model].val = null
    },
    submit() {
      this.setFilterValues();
      this.setIsFiltered(true);
      this.SET_FILTER_MODEL(cloneDeep(this.filter));
      this.SET_SORT_MODEL(cloneDeep(this.sorts));
      this.SET_MODELS([]);
      this.loadModels();
    },
    setFilterValues() {
      this.filter.eye_color.values = this.getEyeColors;
      this.filter.model_type.values = this.getModelTypes;
      this.filter.ethnic_id.values = this.getEthnics;
      this.filter.country_id.values = this.getCountries;
      this.filter.language.values = this.getLanguages;
      (this.filter.language_level.values = this.getLanguageLevels),
          (this.filter.gender_id.values = this.getGenders);
      this.filter.special_views.values = this.getSpecialViews;
      this.filter.model_verification.values = this.modelVerifications;
      this.setActiveAttribute();
    },
    setActiveAttribute() {
      this.filter.attribute_values.toString = [];
      this.getAttributes.map((attr) => {
        const filteredValues = [];
        attr.attributeValues.map((val) => {
          if (this.isActiveAttribute(val.id)) {
            filteredValues.push(val.name);
          }
        });
        if (filteredValues.length) {
          this.filter.attribute_values.toString.push(`${attr.name} : ${filteredValues.join(",")}`);
        }
      });
    },
    isActiveAttribute(id) {
      return this.filter.attribute_values.val.includes(id);
    },
    ...mapMutations("model", ["SET_FILTER_MODEL", "SET_SORT_MODEL","SET_MODELS"]),
    ...mapActions("model", ["loadModels", "setIsFiltered"]),
    ...mapActions("hairColor", ["loadHairColors"]),
    ...mapActions("eyeColor", ["loadEyeColors"]),
    ...mapActions("country", ["loadCountries"]),
    ...mapActions("ethnic", ["loadEthnics"]),
    ...mapActions("modelType", ["loadModelTypes"]),
    ...mapActions("status", ["loadStatuses"]),
    ...mapActions("gender", ["loadGenders"]),
    ...mapActions("language", ["loadLanguages"]),
    ...mapActions("languageLevel", ["loadLanguageLevels"]),
    ...mapActions("specialView", ["loadSpecialViews"]),
    ...mapActions("attribute", ["loadAttributes"]),
    clearFilter() {
      this.setIsFiltered(false);
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_MODEL(cloneDeep(this.filter));
      this.SET_SORT_MODEL(this.sorts);
      this.SET_MODELS([]);
      this.loadModels();
    },
    loadPastFilter() {
      this.filter = cloneDeep({ ...defaultFilter, ...this.getFiltersModel });
    },
  },

  created() {
    this.loadHairColors();
    this.loadEyeColors();
    this.loadEthnics();
    this.loadCountries();
    this.loadModelTypes();
    this.loadStatuses();
    this.loadGenders();
    this.loadLanguages();
    this.loadLanguageLevels();
    this.loadPastFilter();
    this.loadSpecialViews();
    this.loadAttributes();

    if(this.filter.length === 0) {
      this.filter = cloneDeep(defaultFilter);
      // this.filter[this.$route.params.filtersKey].val = this.$route.params.filters;
      this.SET_FILTER_MODEL(cloneDeep(this.filter));
      this.SET_SORT_MODEL('model_updated_not_appended');
    }
    // if(this.$route.params.filtersKey!=null && this.$route.params.filters!=null) {
    //   this.filter = cloneDeep(defaultFilter);
    //   this.filter[this.$route.params.filtersKey].val = this.$route.params.filters;
    //   this.SET_FILTER_MODEL(cloneDeep(this.filter));
    //   this.SET_SORT_MODEL('model_updated_not_appended');
    //
    //   // this.submit()
    // } else {
    //   this.SET_SORT_MODEL('model_updated_not_appended');
    //   this.submit()
    // }
    if(this.$route.params.sorts!=null) {
      this.sorts = this.$route.params.sorts;
      this.SET_SORT_MODEL(this.sorts);
      this.submit()
    }
  },
};
</script>
