<template>
  <v-row class="section-bordered theme-cyan ma-0 mt-2 wrap">
    <list title="active Filters" class="wa__large__page w-100" :filter="true">
      <hr class="mt-0 mb-0" />
      <v-expansion-panels v-model="panel" :readonly="readonly">
        <v-expansion-panel>
          <v-expansion-panel-header class="pa-0" hide-actions>
            <div>
              <v-chip
                class="ma-1 active-filter__item"
                v-for="(filter, i) in activeFilters"
                v-bind:key="i"
              >
                <span  v-if="filter.toString && Array.isArray(filter.toString)">{{filter.toString.join(" , ")}}</span>
                <span v-else-if="filter.type === 'like'">{{filter.name}} : {{filter.val}}</span>
                <span v-else-if="filter.value">{{filter.name}} : {{filter.value}}</span>
                <span v-else-if="filter.type === 'in' && !filter.values">{{filter.name}} : {{filter.val.join(" , ")}}</span>
                <span v-else-if="filter.type === 'in' && !filter.multiple">{{filter.name}} : {{filter.values.find(x=>x.id===filter.val).name}}</span>
                <span v-else-if="filter.type === 'in' && filter.multiple">{{filter.name}} : {{filter.val.map((val)=>filter.values.find(x=>x.id===val).name).join(" , ")}}</span>
                <span v-else-if="filter.type ='between'">{{filter.name}} : from ({{filter.val1}}) to ({{filter.val2}})</span>
              </v-chip>
              <v-chip class="ma-1 active-filter__item">
                sorted by : {{sortedBy}}
              </v-chip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content> </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </list>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      panel: [1, 1],
      readonly: false,
    };
  },
  props: {
    filters: {
      default: {},
    },
    sort:{
      default : {},
    }
  },
  computed: {
    ...mapGetters("model", ["isFilteredModel"]),
    activeFilters() {
      const keys = Object.keys(this.filters);
      if (keys.length !== 0) {
        const activeFilters = [];
        keys.map((key) => {
          const hasVal =
            (this.filters[key].toString && this.filters[key].toString.length)||
            (this.filters[key].val && this.filters[key].val.length) ||
            (this.filters[key].value && this.filters[key].value !== 'all') ||
            typeof this.filters[key].val === "number"  ||
            this.filters[key].val1 ||
            this.filters[key].val2;
          if (hasVal) {
            activeFilters.push(this.filters[key]);
          }
        });
        return activeFilters;
      }
      return [];
    },
    sortedBy(){
      if(this.sort){
        const sortVal = this.sort.substr(0, this.sort.indexOf(','));
        switch (sortVal) {
          case 'created_at':
            return 'joined date'
          default:
            return sortVal
        }
      }
      return "joined data"
      // if(this.sort){

      // }
    }
  },
};
</script>

<style scoped>
.active-filter__item{
  height: auto;
  white-space: normal;
}
</style>
