<template>
  <basic-modal @open="openModal" width="1300" transition="slide-x-transition">
    <template #header>
      <divider
        title="Special Attributes"
        sub-title="let’s make it unique"
        size="large"
      ></divider>
    </template>
    <template #default>
      <div class="px-6 px-md-14 mt-8 pb-16">
        <div class="border rounded py-2 pl-5 my-2" v-for="attr in form.attributes" :key="attr.id">
          <v-row>
            <v-col cols="3"
              ><div class="attr_title d-flex align-center h-100">
                {{ attr.name }}:
              </div></v-col
            >
            <v-col cols="9">
              <v-row>
                <v-col cols="4" v-for="val in attr.attributeValues" :key="val.id">
                  <div class="attributes ml-8">
                    <div>
                      <v-switch
                        inset
                        color="cyan"
                        @change="changeSwitch(val.id)"
                        v-model="switchVal[val.id]"
                        class="social__switch"
                      >
                        <template v-slot:label>{{ val.name }}</template>
                      </v-switch>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-6 py-2 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn-cancel btn__size__18"
          text="CANCEL"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign ml-5 site__button width-auto"
          text="save data"
          :loading="requestLoading"
          @click.native="updateAttrVals"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import AttributeRepository from "@/abstraction/repository/attributeRepository";
const attributeRepository = new AttributeRepository();
import {cloneDeep} from "lodash"
import {mapActions} from "vuex";
export default {
  name: "modal_special_attribute",
  data: () => ({
    requestLoading: false,
    form: {
      attributes: [],
    },
    sendRequestLoading: false,
    model: {},
    attributeValues: [],
    switchVal: [],
  }),
  methods: {
    ...mapActions("model", ["updateAttrValues"]),
    async loadAttributes() {
      try {
        const response = await attributeRepository.index();
        this.form.attributes = cloneDeep(response)
      } catch (error) {
        return error;
      }
    },
    openModal({model}){
      if(model){
        this.model = cloneDeep(model)
        this.attributeValues =this.model.attributeValuesId;
        this.attributeValues.map(id=>{this.switchVal[id]=true})
      }
    },
    changeSwitch(id) {
      if (this.attributeValues.includes(id)) {
        const index = this.attributeValues.indexOf(id);
        this.$delete(this.attributeValues, index);
      } else {
        this.attributeValues.push(id);
      }
    },
    async updateAttrVals() {
      try {
        this.sendRequestLoading = true;
        this.updateAttrValues({ id: this.model.id, values: this.attributeValues });
        this.$_closeModal();
      } catch (e) {
        return e;
      } finally {
        this.sendRequestLoading = false;
      }
    },
  },
  created() {
    this.loadAttributes();
  },
};
</script>
<style scoped>
.attr_title {
  font-family: "montserrat-light";
  font-size: 22px;
  color: var(--color-black);
}

.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
</style>
<style>
.social__switch .v-input__control .v-input__slot .v-label {
  word-break: break-word !important;
}
</style>
